export const getFormattedDate = (date: string): string => {
  const formattedDate = new Date(date).toLocaleDateString('en-GB', {
    timeZone: 'UTC',
    month: 'long',
    day: '2-digit',
    year: 'numeric',
  });

  const spl = formattedDate.split(' ');
  return `${spl[1]} ${spl[0]}, ${spl[2]}`;
};
