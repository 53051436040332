import React from 'react';
import { graphql, Link } from 'gatsby';
import { Layout } from '../components/Layout';
import { SEO } from '../components/SEO';
import { Button, Flex, Heading, HStack, Text } from '@chakra-ui/react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import { getConfig } from '../config';
import { getFormattedDate } from '../helpers/getFormattedDate';
import { theme } from '../theme';

type Data = {
  data: {
    markdownRemark: {
      frontmatter: {
        title: string;
        date: string;
        author: string;
        subTitle: string;
        featuredImage: {
          childImageSharp: {
            gatsbyImageData: IGatsbyImageData;
          };
        };
      };
      html: string;
    };
    blog: {
      posts: {
        fields: {
          slug: string;
        };
        frontmatter: {
          title: string;
        };
        id: string;
      }[];
    };
  };
};

export const query = graphql`
  query BlogQuery($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        author
        subTitle
        featuredImage {
          childImageSharp {
            gatsbyImageData(width: 750, layout: CONSTRAINED, quality: 90)
          }
        }
      }
    }
    blog: allMarkdownRemark {
      posts: nodes {
        fields {
          slug
        }
        frontmatter {
          title
        }
        id
      }
    }
  }
`;
const config = getConfig();
const platformUrl = `${config.platformUrl}`;

const BlogPost: React.FC<Data> = ({ data }) => {
  const {
    frontmatter: { title, author, subTitle, date, featuredImage },
    html,
  } = data.markdownRemark;
  const { posts } = data.blog;
  const newDate = getFormattedDate(date);

  return (
    <Layout>
      <SEO title={`${title} | Canyou`} description={subTitle} />
      <Flex direction="column" align="center">
        <Flex
          mx={6}
          my={{ base: 3, md: '70px' }}
          maxW="1460"
          direction="column"
        >
          <Flex maxW="750px" direction="column">
            <Flex px={5} direction="column">
              <Heading
                as="h1"
                mt={3}
                lineHeight="lg"
                textAlign={{ base: 'center', lg: 'left' }}
              >
                {title}
              </Heading>
              <HStack
                spacing={2}
                my={3}
                justify={{ base: 'center', lg: 'left' }}
              >
                <Text fontWeight="medium">{author}</Text>
                <Text>{newDate}</Text>
              </HStack>
            </Flex>
            <Heading
              as="h2"
              color="rgba(78,78,78,.88)"
              size="md"
              mt={3}
              lineHeight="lg"
              textAlign={{ base: 'center', lg: 'left' }}
            >
              {subTitle}
            </Heading>
          </Flex>
          <Flex
            my={3}
            justify="space-between"
            direction={{ base: 'column', xl: 'row' }}
          >
            <Flex maxW="750px" direction="column">
              <Flex my={5}>
                <GatsbyImage
                  style={{ borderRadius: '5%' }}
                  alt={title}
                  image={featuredImage.childImageSharp.gatsbyImageData}
                />
              </Flex>
              {/* eslint-disable-next-line react/no-danger */}
              <Flex
                sx={{
                  fontFamily: theme.fonts.body,
                  h2: {
                    fontSize: theme.components.Heading.sizes.md.fontSize,
                    lineHeight: 1,
                    marginTop: ['10px', '30px'],
                    marginBottom: ['10px'],
                  },
                  ul: {
                    listStylePosition: 'inside',
                  },
                }}
                dangerouslySetInnerHTML={{ __html: html }}
                my={5}
                py={3}
                lineHeight={2}
                direction={{ base: 'column' }}
              />
              <Flex
                direction={{ base: 'column', lg: 'row' }}
                justify="space-between"
                align="center"
                borderBottom="1px solid rgb(0, 90, 174)"
                borderTop="1px solid rgb(0, 90, 174)"
                p={8}
                my={5}
              >
                <Flex direction="column" align="space-between">
                  <Heading
                    as="h3"
                    size="32px"
                    fontWeight="600"
                    color="brand.blue"
                    py={5}
                    textAlign={{ base: 'center', lg: 'left' }}
                  >
                    Eliminate paperwork chaos with Canyou.
                  </Heading>
                  <Text
                    fontSize="18px"
                    py={5}
                    textAlign={{ base: 'center', lg: 'left' }}
                  >
                    Streamline and automate compliance, training and business
                    operations with Canyou&apos;s easy to use toolkit.
                  </Text>
                </Flex>
                <Button size="lg" as="a" href={platformUrl} minW="160px">
                  Learn more
                </Button>
              </Flex>
            </Flex>
            <Flex
              as="aside"
              position="sticky"
              top={0}
              direction="column"
              height="50vh"
              pt={5}
              px="30px"
            >
              <Heading
                as="h3"
                size="22px"
                fontWeight="600"
                pb={5}
                borderBottom="1px solid #d6d6d6"
                color="#858a8f"
                textAlign={{ base: 'center', lg: 'left' }}
              >
                Other Posts
              </Heading>
              {posts.slice(0, 3).map((postDetails) => (
                <Flex
                  as="article"
                  key={postDetails.id}
                  height="75px"
                  borderBottom="1px solid #d6d6d6"
                  align="center"
                  justify={{ base: 'center', lg: 'left' }}
                  px={3}
                >
                  <Link to={`/blog${postDetails.fields.slug}`}>
                    <Heading
                      as="h4"
                      size="20px"
                      fontWeight="medium"
                      color="rgb(1, 22, 77)"
                      transition="color 120ms"
                      _hover={{ color: 'brand.blue' }}
                    >
                      {postDetails.frontmatter.title}
                    </Heading>
                  </Link>
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};

export default BlogPost;
